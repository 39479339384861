export default function FullPageSpinner() {
  return (
    <div className='min-h-screen flex items-center justify-center bg-black'>
      <div className='relative flex items-center justify-center'>
        <div className='h-[150px] md:h-[200px] w-[150px] md:w-[200px] border-0 border-solid border-black rounded-full absolute loader-1' />
        <div className='h-[150px] md:h-[200px] w-[150px] md:w-[200px] border-0 border-solid border-black rounded-full absolute loader-2' />
        <div className='h-[150px] md:h-[200px] w-[150px] md:w-[200px] border-0 border-solid border-black rounded-full absolute loader-3' />
        <span className='text-white'>Loading...</span>
      </div>
    </div>
  )
}
