import Footer from 'components/footer'
import Navbar from 'components/navbar'
import Router from 'pages/router'
import { useLocation } from 'react-router-dom'

export default function App() {
  const location = useLocation()
  const pathData = ['/dashboard']
  return (
    <div>
      {pathData.includes(location.pathname) ? null : (
        <div className='mb-20'>
          <Navbar />
        </div>
      )}
      <div>
        <Router />
      </div>
      {pathData.includes(location.pathname) ? null : <Footer />}
    </div>
  )
}
