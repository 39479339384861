export const navbarData = [
  {
    id: 4,
    name: 'Home',
    path: '/',
  },
  {
    id: 1,
    name: 'About',
    path: '/about',
  },
  {
    id: 2,
    name: 'News & Views',
    path: '/news-and-views',
  },
  {
    id: 4,
    name: 'Contact',
    path: '/contact',
  },
  {
    id: 5,
    name: 'Login',
    path: '/login',
  },
]
