import logo from 'assets/images/logo.png'
import {
  BsEnvelopeFill,
  BsFacebook,
  BsFillCaretRightFill,
  BsInstagram,
  BsLinkedin,
  BsTelephoneFill,
  BsYoutube,
} from 'react-icons/bs'
import { HiLocationMarker } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import Container from './container'

export default function Footer() {
  return (
    <footer className='bg-[#111827] text-slate-200 relative'>
      <Container className=''>
        <div className='py-20 grid grid-cols-12 gap-6'>
          <div className='col-span-12 lg:col-span-5'>
            <img src={logo} alt='' />
            <p className='mt-4 text-sm w-full lg:w-10/12'>
              A leading marketing agency that offers matchless creative solutions, website building,
              and digital marketing services that will help you make a lasting impression.
            </p>
            <ul className='flex mt-6'>
              <li className='bg-white text-[#111827] p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
                <a href='' target='_blank' rel='noreferrer'>
                  <BsFacebook />
                </a>
              </li>
              <li className='bg-white text-[#111827] p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
                <a href='' target='_blank' rel='noreferrer'>
                  <BsInstagram />
                </a>
              </li>
              <li className='bg-white text-[#111827] p-2 rounded-md mx-2 hover:scale-110 ease-in-out duration-500'>
                <a href='' target='_blank' rel='noreferrer'>
                  <BsYoutube />
                </a>
              </li>
              <li className='bg-white text-[#111827] p-2 rounded-md ml-2 hover:scale-110 ease-in-out duration-500'>
                <a href='' target='_blank' rel='noreferrer'>
                  <BsLinkedin />
                </a>
              </li>
            </ul>
          </div>
          <div className='col-span-12 lg:col-span-3'>
            <h4 className='text-2xl font-bold mb-4 text-white'>Important Links</h4>
            <ul>
              <li className='mb-1'>
                <Link to='/about' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:font-medium'>About</span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/news-and-views' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:font-medium'>News & Views</span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/contact' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:font-medium'>Contact</span>
                </Link>
              </li>
              <li className='mb-1'>
                <Link to='/' className='group flex items-center text-lg'>
                  <BsFillCaretRightFill className='inline-block group-hover:translate-x-1 duration-500 ease-in-out ' />{' '}
                  <span className='ml-1 group-hover:font-medium'>Login</span>
                </Link>
              </li>
            </ul>
          </div>
          <div className='col-span-12 lg:col-span-4'>
            <h4 className='text-2xl font-bold mb-4 text-white'>Contact info</h4>
            <div className='flex gap-1'>
              <span>
                <HiLocationMarker className='h-6 w-6' />
              </span>
              <span className='text-lg'>Police Super office, Brahmanbaria</span>
            </div>
            <div className='flex gap-2 items-center my-2'>
              <span>
                <BsTelephoneFill className='h-5 w-5' />
              </span>
              <span className='text-lg'>+8801714265335</span>
            </div>
            <div className='flex gap-2 items-center'>
              <span>
                <BsEnvelopeFill className='h-5 w-5' />
              </span>
              <span className='text-lg'>contact@onemilliontreeproject.com</span>
            </div>
          </div>
        </div>
        <div className='text-center  py-6 border-t border-slate-800'>
          <p>
            © COPYRIGHT {new Date().getFullYear()} BY{' '}
            <a href='https://take.win' target='_blank' rel='noreferrer'>
              TAKE.WIN
            </a>
          </p>
        </div>
      </Container>
    </footer>
  )
}
