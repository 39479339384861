import { createContext, useContext, useMemo, useState } from 'react'

const Context = createContext()

export default function ContextProvider({ children }) {
  const [loggedInUserInfo, setLoggedInUserInfo] = useState(true)
  const value = useMemo(() => ({ loggedInUserInfo, setLoggedInUserInfo }), [loggedInUserInfo])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const ContextState = () => {
  return useContext(Context)
}
