import { ContextState } from 'components/context-provider'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

const Home = React.lazy(() => import('./home'))
const About = React.lazy(() => import('./about'))
const NewsAndViews = React.lazy(() => import('./news-and-views'))
const NewsAndViewsDetails = React.lazy(() => import('./news-and-views-details'))
const Contact = React.lazy(() => import('./contact'))
const Dashboard = React.lazy(() => import('./dashboard'))
const SignUp = React.lazy(() => import('./sign-up'))
const Login = React.lazy(() => import('./login'))
const NotFound = React.lazy(() => import('./not-found'))

export default function Router() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/news-and-views' element={<NewsAndViews />} />
        <Route path='/news-and-views/:slug' element={<NewsAndViewsDetails />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/create-account' element={<SignUp />} />
        <Route path='/login' element={<Login />} />
        <Route
          path='/dashboard'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  )
}

function PrivateRoute({ children }) {
  const { loggedInUserInfo } = ContextState()
  return loggedInUserInfo ? children : <Navigate to='/login' />
}
