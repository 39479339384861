import logo from 'assets/images/logo.png'
import { navbarData } from 'data/navbar'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Container from './container'
import SideNavbar from './side-navbar'

export default function Navbar() {
  const [open, setOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(true)
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset
      const isScrolledDown = prevScrollPos < currentScrollPos

      setPrevScrollPos(currentScrollPos)

      if (isScrolledDown && currentScrollPos > 50) {
        setIsSticky(false)
      } else {
        setIsSticky(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos])

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <div>
      <nav
        className={`fixed top-0 left-0 w-full bg-gray-900 transition-transform duration-300 ${
          isSticky ? '' : 'transform -translate-y-full'
        }`}
        style={{ zIndex: '9999' }}
      >
        <Container>
          <div className='flex items-center justify-between h-20'>
            <Link to='/' className='font-bold text-2xl text-white'>
              <img className='' src={logo} alt='Logo' />
            </Link>

            <div className='hidden lg:block'>
              <ul className='ml-10 flex items-baseline space-x-4'>
                {navbarData?.map((data) => (
                  <Link
                    to={data?.path}
                    key={data?.id}
                    className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                  >
                    {data?.name}
                  </Link>
                ))}
              </ul>
            </div>

            <div className='block lg:hidden'>
              <button
                className='p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700'
                onClick={() => setOpen(!open)}
              >
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
              </button>
            </div>
          </div>
        </Container>
      </nav>
      <SideNavbar open={open} handleOpen={handleOpen} />
    </div>
  )
}
