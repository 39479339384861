import { navbarData } from 'data/navbar'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { Link, NavLink } from 'react-router-dom'

function SideNavbar({ open, handleOpen }) {
  return (
    <Drawer open={open} onClose={handleOpen} direction='left' className='lg:hidden'>
      <div className='px-2 pt-8 h-full'>
        <Link to='/'>
          <img src='https://take.win/static/media/logo.e16fa65a7aa38ff33d30.webp' alt='logo' />
        </Link>
        <ul className='mt-8'>
          {navbarData?.map((data) => (
            <li key={data?.id}>
              <NavLink
                to={data?.path}
                className={({ isActive }) =>
                  ` block w-full transition-all duration-500 py-2 px-4 rounded ${
                    isActive ? 'text-white bg-gray-900 font-semibold' : 'font-medium'
                  }`
                }
              >
                {data?.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </Drawer>
  )
}
export default SideNavbar
