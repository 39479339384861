/* eslint-disable react/jsx-filename-extension */
import ContextProvider from 'components/context-provider'
import FullPageSpinner from 'components/full-page-spinner'
import React from 'react'
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <QueryClientProvider client={queryClient}>
    <ContextProvider>
      <ClearBrowserCacheBoundary auto duration={60000}>
        <BrowserRouter>
          <React.Suspense fallback={<FullPageSpinner />}>
            <App />
          </React.Suspense>
        </BrowserRouter>
      </ClearBrowserCacheBoundary>
    </ContextProvider>
  </QueryClientProvider>,
)

serviceWorkerRegistration.register()
reportWebVitals()
